/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 19, 2017 */
@font-face {
  font-family: 'robotoblack';
  src: url("/fonts/roboto-black-webfont.woff2") format("woff2"), url("/fonts/roboto-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'robotolight';
  src: url("/fonts/roboto-light-webfont.woff2") format("woff2"), url("/fonts/roboto-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'robotoregular';
  src: url("/fonts/roboto-regular-webfont.woff2") format("woff2"), url("/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'TitilliumWeb';
  src: url("/fonts/TitilliumWeb-Regular.woff2") format("woff2"), url("/fonts/TitilliumWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'TitilliumWeb';
  src: url("/fonts/TitilliumWeb-Bold.woff2") format("woff2"), url("/fonts/TitilliumWeb-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

/* base <tag> styles like a, h1, html, body
and only that: no classes, no ids */
*,
*:after,
*:before {
  box-sizing: border-box; }

body,
html {
  margin: 0; }

a {
  color: inherit;
  text-decoration: underline; }
  a:hover, a:focus {
    text-decoration: none; }
  a:active {
    text-decoration: underline; }

a:not([href]),
a:active:not([href]) {
  text-decoration: none; }

img {
  width: auto;
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p,
dl,
dt,
dd,
blockquote,
fieldset,
legend,
figure {
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: normal; }

input,
textarea,
select,
button {
  font: inherit;
  letter-spacing: inherit;
  color: #000; }

option {
  color: initial; }

ul {
  padding: 0;
  list-style: none; }

fieldset {
  border: none; }

cite {
  font-style: normal; }

legend {
  color: inherit;
  display: block; }

.titreArticle, .StaticPage-contentTitle, .Product-contentTitle {
  background: #eeeeee;
  color: #006f93;
  font-family: 'robotoregular', sans-serif;
  font-size: 25px;
  font-size: 1.5625rem;
  margin-bottom: 1em;
  padding: .32em 0;
  text-align: center;
  word-wrap: break-word; }
  @media (max-width: 26.25em) {
    .titreArticle, .StaticPage-contentTitle, .Product-contentTitle {
      font-size: 17px;
      font-size: 1.0625rem; } }

.sousTitre, .StaticPage h4, .Product-content h4, table caption {
  font-family: 'robotoblack', sans-serif;
  font-weight: normal;
  margin: .875em 0 .125em;
  padding: 0;
  text-align: left;
  font-size: 16px;
  font-size: 1rem; }
  .sousTitre:first-of-type, .StaticPage h4:first-of-type, .Product-content h4:first-of-type, table caption:first-of-type {
    margin-top: 0; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sf-toolbar {
  display: none !important; }

html, body {
  height: 100%; }

html {
  background: #fff;
  font-family: 'robotoregular', sans-serif; }

.Layout {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: calc(-22px - 32px); }

.Layout:after {
  content: "";
  display: block; }

header,
.Main,
.Alerts,
.Footer-menu {
  width: auto;
  display: block;
  margin: auto;
  padding: 10px 20px 0; }
  @media (min-width: 64em) {
    header,
    .Main,
    .Alerts,
    .Footer-menu {
      max-width: 890px; } }

.Footer-menu {
  padding: 0; }

.Alerts {
  padding: 0 20px;
  margin: 0 auto !important;
  float: none !important; }

.Main {
  padding-top: 1em; }

.Main--noGutter {
  padding: 0;
  max-width: none; }

footer, .Layout:after {
  min-height: calc(22px + 32px); }

footer {
  background: #646464;
  padding: 1em 0; }

.Brand {
  font-size: 14px;
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 10px 0px #656565;
  background: white;
  width: 100%;
  height: 8rem;
  color: #646464;
  clear: both;
  float: left;
  margin-top: 1rem; }
  .Brand > div {
    text-align: center;
    width: 33%; }
    .Brand > div .Brand-logo {
      max-height: 6rem; }
    .Brand > div a {
      color: #006f93; }

.Brand-email {
  display: block;
  padding-top: 2.5rem;
  background: url("/images/email.png") no-repeat center top;
  color: #006f93; }

.Header {
  overflow: auto;
  display: table;
  padding: 1em 0 0 0;
  width: 100%; }

.Header-logo {
  display: table-cell; }
  @media (min-width: 48em) {
    .Header-logo {
      width: 320px; } }

.Header-baseline {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.Header--center {
  text-align: center; }

@media (max-width: 47.99em) {
  .Header,
  .Header-logo,
  .Header-baseline {
    display: block;
    text-align: center;
    width: 100%; } }

.Header-baseline {
  font-size: 25px;
  font-size: 1.5625rem;
  color: #813788;
  padding: 10px 50px;
  font-family: 'robotoblack', sans-serif;
  text-align: center;
  font-weight: normal;
  text-align: center;
  position: relative;
  margin-top: .25em;
  border-top: 1px solid #bbbbbb; }
  @media (min-width: 48em) {
    .Header-baseline {
      border: 0; } }
  .Header-baseline:after, .Header-baseline:before {
    content: "";
    width: 18px;
    height: 45px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -22.5px;
    background: url("/images/window-left.png") no-repeat left center; }
  .Header-baseline:after {
    display: block;
    background: url("/images/window-right.png") no-repeat right center;
    right: auto;
    left: 0; }

.Footer-menuList {
  display: block;
  text-align: center; }

.Footer-menuItem {
  display: inline-block; }
  .Footer-menuItem:last-child ::after {
    display: none; }

.Footer-menuLink {
  font-size: 14px;
  font-size: 0.875rem;
  font-family: 'robotoregular', sans-serif;
  color: #fff;
  text-decoration: none; }
  .Footer-menuLink::after {
    display: inline-block;
    margin: 0 0 0 .25em;
    content: '|'; }
  .Footer-menuLink:hover, .Footer-menuLink:focus {
    text-decoration: underline; }

.alert {
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  position: relative; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible .close {
  color: inherit;
  border: 0;
  padding: 0;
  cursor: pointer;
  background: transparent; }
  .alert-dismissible .close:hover, .alert-dismissible .close:focus {
    text-decoration: underline; }

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d0e9c6; }
  .alert-success hr {
    border-top-color: #c1e2b3; }
  .alert-success .alert-link {
    color: #2b542c; }

.alert-info,
.alert-notice {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bcdff1; }
  .alert-info hr,
  .alert-notice hr {
    border-top-color: #a6d5ec; }
  .alert-info .alert-link,
  .alert-notice .alert-link {
    color: #245269; }

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faf2cc; }
  .alert-warning hr {
    border-top-color: #f7ecb5; }
  .alert-warning .alert-link {
    color: #66512c; }

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebcccc; }
  .alert-danger hr {
    border-top-color: #e4b9b9; }
  .alert-danger .alert-link {
    color: #843534; }

[role="tablist"] a {
  font-size: 15.33px;
  font-size: 0.958125rem;
  font-family: 'robotoregular', sans-serif;
  cursor: pointer;
  align-self: auto;
  display: block;
  flex: 1 1 auto;
  background: #eeeeee;
  border-bottom: 1px solid #bbbbbb;
  color: #646464;
  text-align: center;
  line-height: 2em;
  padding: .6em 30px; }
  [role="tablist"] a[aria-selected="true"] {
    font-weight: bold;
    color: #fff;
    background: #813788;
    border-color: #7b247b; }
  [role="tablist"] a:hover, [role="tablist"] a:focus {
    color: #fff;
    background: #813788;
    border-color: #7b247b; }
  [role="tablist"] a#label_infos_commande {
    line-height: 1em; }

.js-tabcontent[aria-hidden=true] {
  display: none; }

.StaticPage--center {
  text-align: center; }

.StaticPage {
  font-family: 'robotoregular', sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  color: #646464; }
  .StaticPage p {
    margin: 0 0 .5em 0; }
  .StaticPage a {
    color: #006f93; }
  .StaticPage strong,
  .StaticPage .emphasis {
    font-family: 'robotoblack', sans-serif;
    font-weight: normal; }
  .StaticPage .Document {
    text-align: center; }
  .StaticPage ul {
    list-style-type: disc;
    padding: 0 0 0 1.5em; }
    .StaticPage ul li {
      padding: .25em 0; }
  .StaticPage ul.Documents {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 1em 0;
    padding: .5em 0; }
    .StaticPage ul.Documents li {
      width: 33.3333333333%;
      padding: 0.75em; }
      @media (min-width: 26.26em) {
        .StaticPage ul.Documents li {
          width: 20%; } }
      @media (min-width: 48.01em) {
        .StaticPage ul.Documents li {
          width: 14.2857142857%; } }
      .StaticPage ul.Documents li .a,
      .StaticPage ul.Documents li a {
        display: block; }
        .StaticPage ul.Documents li .a img,
        .StaticPage ul.Documents li a img {
          max-width: 90%; }
  .StaticPage .List-itemLabel {
    font-weight: bold; }

.StaticPage-list {
  margin-bottom: 2em; }

.StaticPage-downloadLink {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1em; }

.StaticPage-separator {
  margin: 1em 0 !important; }

.StaticPage--center .StaticPage-contentWrapper {
  width: 90%;
  text-align: center;
  display: inline-block; }

.StaticPage--center .StaticPage-list {
  display: inline-block;
  text-align: left; }

.StaticPage-contentSubtitle {
  font-size: 32px;
  font-size: 2rem;
  margin-bottom: 1em;
  color: #7b247b; }

.Product {
  zoom: 1;
  padding: 1.3888888889% 0; }
  .Product:before, .Product:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .Product:after {
    clear: both; }
  .Product h4 {
    margin-bottom: 0.5rem; }

.burger {
  display: block;
  border: 0;
  background: transparent;
  padding: 0;
  margin-top: -30px;
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 500;
  background: url("/images/burger.png") no-repeat center center; }
  .burger:hover, .burger:focus {
    background: url("/images/burger-hover.png") no-repeat center center; }

.Product-tabs {
  display: none;
  position: absolute;
  z-index: 99;
  left: 0;
  padding: 0 27px;
  background: #eeeeee; }
  @media (max-width: 47.99em) {
    .Product-tabs:before {
      content: '';
      display: block;
      height: 40px;
      width: 40px;
      margin: 5px 0;
      position: relative; } }

@media (min-width: 48em) {
  .burger {
    display: none; }
  .Product-tabs {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    float: left;
    padding-left: 1.3888888889%;
    padding-right: 1.3888888889%;
    display: block !important;
    top: 0 !important;
    position: relative;
    padding: 0; }
  .Product-content {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 75%;
    float: right;
    padding-left: 1.3888888889%;
    padding-right: 1.3888888889%; } }

.Product-content {
  font-family: 'robotoregular', sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  color: #646464; }
  .Product-content p {
    margin: 0 0 .5em 0; }
  .Product-content a {
    color: #006f93; }
  .Product-content strong,
  .Product-content .emphasis {
    font-family: 'robotoblack', sans-serif;
    font-weight: normal; }

@media (min-width: 48em) {
  .Product-column {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left;
    padding-left: 1.3888888889%;
    padding-right: 1.3888888889%;
    border-right: 1px solid #bbbbbb; }
    .Product-column + .Product-column {
      border-right: 0;
      border-left: 1px solid #bbbbbb;
      position: relative;
      left: -1px; } }

.Product-typology,
.Product-brand,
.Product-maker,
.Product-distributor {
  padding: .5em 0;
  border-bottom: 1px solid #bbbbbb; }

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse; }
  table tbody td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #bbbbbb; }
  table tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9; }
  table tbody tr:hover {
    background-color: #f5f5f5; }

.Document-imgWrapper {
  margin: 0; }

.Document-img {
  max-height: 75px; }

.Document-file a {
  display: flex;
  align-items: center; }
  .Document-file a .Document-downloadIcon {
    flex-shrink: 0;
    margin-right: 0.5rem; }

.Link {
  display: flex;
  align-items: center; }
  .Link img {
    flex-shrink: 0;
    margin-right: 0.5rem; }

.Link--image {
  display: block; }
  .Link--image img {
    max-height: 75px; }

.Home {
  margin-top: 2rem;
  font-family: 'TitilliumWeb'; }

.Home-presentation {
  display: flex; }
  @media (min-width: 64em) {
    .Home-presentation {
      max-width: 890px;
      margin: 0 auto; } }

.Home-presentationContent {
  width: 50%;
  padding: 1rem;
  flex-shrink: 0;
  text-align: right;
  color: #813788; }
  .Home-presentationContent p {
    margin-bottom: 1rem; }
  @media screen and (max-width: 42.8em) {
    .Home-presentationContent {
      width: 100%;
      padding: 1rem 2rem;
      text-align: left;
      margin-bottom: 1rem; } }

.Home-presentationTitle {
  font-size: 25px;
  font-size: 1.5625rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: .5em; }

@media screen and (max-width: 42.8em) {
  .Home-presentationLinkWrapper {
    text-align: center; } }

.Home-presentationLink {
  font-size: 14px;
  font-size: 0.875rem;
  display: inline-block;
  position: relative;
  height: 2rem;
  line-height: 1.75rem;
  padding-right: 2rem;
  padding-left: 0.5rem;
  z-index: 10;
  border-radius: 25px;
  border: 2px solid #813788;
  text-transform: uppercase;
  text-decoration: none !important;
  font-weight: bold;
  background-color: #fff; }
  .Home-presentationLink:after {
    content: '';
    position: absolute;
    right: 2px;
    top: 2px;
    width: 1.5rem;
    height: 1.5rem;
    background: url("/images/chevron_right.png") no-repeat center center;
    background-size: 1.5rem; }
  @media (max-width: 26.25em) {
    .Home-presentationLink {
      font-size: 12px;
      font-size: 0.75rem; } }

.Home-imagesWrapper {
  position: relative;
  margin-left: 1rem; }
  @media screen and (max-width: 42.8em) {
    .Home-imagesWrapper {
      display: none; } }

.Home-telephoneImg {
  position: relative;
  width: 20rem;
  z-index: 3; }

.Home-etiquetteImg {
  position: absolute;
  left: 18rem;
  top: 5rem;
  width: 13rem;
  margin-left: -5rem;
  z-index: 1; }

.Home-searchForm {
  position: relative;
  padding: 7rem 0 4rem;
  width: 100%;
  margin-top: -5.5rem;
  z-index: 2;
  text-align: center;
  background-color: #006f93;
  clip-path: polygon(50% 15%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0 0); }
  @media screen and (max-width: 42.8em) {
    .Home-searchForm {
      margin-top: 0; } }
  @media (max-width: 26.25em) {
    .Home-searchForm {
      padding: 3.5rem 0 2rem; } }

.Home-helpLink {
  display: block;
  color: #4994AC; }

.Home-searchFormInputWrapper {
  position: relative;
  height: 3rem;
  max-width: 25rem;
  margin: auto;
  border-radius: 25px;
  background-color: #fff; }
  @media (max-width: 26.25em) {
    .Home-searchFormInputWrapper {
      max-width: 85%; } }

.Home-searchInput {
  height: 3rem;
  width: 100%;
  padding-right: 3rem;
  padding-left: 1.5rem;
  border: none;
  background-color: transparent; }
  .Home-searchInput::placeholder {
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic; }

.Home-submitButton {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 2.5rem !important;
  height: 2.5rem !important;
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'TitilliumWeb' !important;
  font-weight: bold !important;
  -webkit-appearance: inherit; }

.Home-tooltipButton {
  font-size: 18px;
  font-size: 1.125rem;
  height: 1.8rem;
  width: 1.8rem;
  padding: 0;
  font-weight: bold;
  border-radius: 50%;
  border: none;
  background-color: #4994AC;
  color: #fff;
  -webkit-appearance: inherit; }

.Home-reference {
  margin-right: 0.5rem;
  font-style: italic;
  color: #fff; }

.Home-moreInfosContainer {
  background-color: #f5f5f5; }

.Home-moreInfos {
  margin-top: -3rem;
  padding: 5rem 4rem 0; }
  @media (min-width: 64em) {
    .Home-moreInfos {
      max-width: 890px;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 26.25em) {
    .Home-moreInfos {
      padding: 5rem 0 0; } }

.Home-stepsTitle,
.Home-videoTitle,
.Home-stepsSubtitle {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #006f93; }
  @media screen and (max-width: 42.8em) {
    .Home-stepsTitle,
    .Home-videoTitle,
    .Home-stepsSubtitle {
      text-align: left; } }

.Home-stepsTitle {
  font-size: 24px;
  font-size: 1.5rem; }
  @media (max-width: 26.25em) {
    .Home-stepsTitle {
      font-size: 20px;
      font-size: 1.25rem; } }

.Home-stepsSubtitle {
  font-size: 15px;
  font-size: 0.9375rem;
  margin-bottom: 2.5rem; }
  @media (max-width: 26.25em) {
    .Home-stepsSubtitle {
      font-size: 13px;
      font-size: 0.8125rem; } }

.Home-videoTitle {
  font-size: 24px;
  font-size: 1.5rem;
  width: 20%;
  text-align: right;
  margin-bottom: 0.5rem; }
  @media screen and (max-width: 50em) {
    .Home-videoTitle {
      width: 100%;
      text-align: left; } }
  @media (max-width: 26.25em) {
    .Home-videoTitle {
      font-size: 20px;
      font-size: 1.25rem;
      text-align: center; } }

.Home-steps {
  display: flex;
  margin-bottom: 6rem;
  background-color: #006f93; }
  @media screen and (max-width: 42.8em) {
    .Home-steps {
      flex-direction: column;
      height: 65rem;
      width: 70%;
      margin: 0 auto 2rem; } }

.Home-stepItem {
  font-size: 13px;
  font-size: 0.8125rem;
  width: 16%;
  padding: 2rem 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff; }
  .Home-stepItem:nth-child(even) {
    width: 28%;
    background-color: #4994AC;
    clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 20% 50%); }
    @media screen and (max-width: 42.8em) {
      .Home-stepItem:nth-child(even) {
        width: 100%;
        height: 24%;
        padding-top: 5rem;
        clip-path: polygon(50% 20%, 100% 0, 100% 80%, 50% 100%, 0 80%, 0 0); } }
  @media screen and (max-width: 42.8em) {
    .Home-stepItem {
      width: 100%;
      height: 18%; } }

.Home-stepIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  margin-bottom: 1rem; }

.Home-stepIcon {
  width: 3.5rem; }

.Home-videoSection {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5rem; }
  @media screen and (max-width: 50em) {
    .Home-videoSection {
      flex-direction: column; } }
  @media (max-width: 26.25em) {
    .Home-videoSection {
      padding-bottom: 0; } }

.Home-videoIframe {
  border: none; }
  @media screen and (max-width: 50em) {
    .Home-videoIframe {
      width: 100%; } }
  @media (max-width: 26.25em) {
    .Home-videoIframe {
      height: auto; } }

@media (max-width: 26.25em) {
  .Home-titleWrapper {
    padding: 0 2rem; } }

form p {
  overflow: auto;
  zoom: 1;
  padding: 10px 0;
  margin: 0; }
  form p.submit {
    text-align: right; }

label {
  font-size: 17px;
  font-size: 1.0625rem;
  color: #646464;
  display: block;
  padding: 0 .5em;
  float: left;
  width: 25%;
  text-align: right; }
  @media (max-width: 47.99em) {
    label {
      float: none;
      width: auto;
      text-align: left;
      padding: .5em 0 0 0; } }

input,
textarea {
  border: 1px solid #646464;
  width: 75%; }
  @media (max-width: 47.99em) {
    input,
    textarea {
      width: 95%; } }
  input[type='file'],
  textarea[type='file'] {
    border: 0; }

input[type='submit'] {
  background: #813788;
  border: 1px solid #813788;
  border-radius: 20px;
  color: #fff;
  font-family: 'robotoblack', sans-serif;
  font-size: 16.67px;
  font-size: 1.041875rem;
  font-weight: normal;
  text-align: center;
  padding: .3em 1em;
  margin: 0 0 1em 0;
  cursor: pointer;
  width: auto; }
  input[type='submit']:hover, input[type='submit']:focus {
    background: #fff;
    color: #813788; }

input[type="text"]:disabled {
  background: #eee; }
