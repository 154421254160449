.Product {
	@include clearfix;
	padding: gutter() 0;

	h4 {
		margin-bottom: 0.5rem;
	}
}

.burger {
	display: block;
	border: 0;
	background: transparent;
	padding: 0;
	margin-top: -30px;
	height: 40px;
	width: 40px;
	position: relative;
	z-index: 500;
	background: url('/images/burger.png') no-repeat center center;
	@include hover {
		background: url('/images/burger-hover.png') no-repeat center center;
	}
}

.Product-tabs {
	display: none;
	position: absolute;
	z-index: 99;
	left: 0;
    padding: 0 27px;
    background: $verylightgrey;
	@include media('<tablet') {
		&:before {
			content: '';
			display: block;
			height: 40px;
			width: 40px;
			margin: 5px 0;
			position: relative;
		}
	}
}

@include media('>=tablet') {
	.burger {
		display: none;
	}
	.Product-tabs {
		@include span(3 of 12);
		display: block !important;
		top: 0 !important;
		position: relative;
		padding: 0;
	}
	.Product-content {
		@include span(last 9 of 12);
	}
}

.Product-content {
	font-family: 'robotoregular', sans-serif;
	@include font-size(14);
	color: $grey;
	h4 {
		@extend .sousTitre;
	}
	p {
		margin: 0 0 .5em 0;
	}
	a {
		color: $blue;
	}
	strong,
	.emphasis {
		font-family: 'robotoblack', sans-serif;
		font-weight: normal;
	}
}

.Product-contentTitle {
	@extend .titreArticle;
}


@include media('>=tablet') {
	.Product-column {
		@include span(6 of 12);
		border-right: 1px solid $lightgrey;
		+ .Product-column {
			border-right: 0;
			border-left: 1px solid $lightgrey;
			position: relative;
			left: -1px;
		}
	}
}

.Product-typology,
.Product-brand,
.Product-maker,
.Product-distributor {
	padding: .5em 0;
	border-bottom: 1px solid $lightgrey;
}


table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
    border-spacing: 0;
    border-collapse: collapse;
	caption {
		@extend .sousTitre;
	}
	tbody td {
		padding: 8px;
		line-height: 1.42857143;
		vertical-align: top;
		border-top: 1px solid $lightgrey;
	}
	& tbody tr:nth-of-type(odd) {
		background-color: #f9f9f9;
	}
	& tbody tr:hover {
		background-color: #f5f5f5;
	}
}

.Document-imgWrapper {
	margin: 0;
}

.Document-img {
	max-height: 75px;
}

.Document-file {
	a {
		display: flex;
		align-items: center;

		.Document-downloadIcon {
			flex-shrink: 0;
			margin-right: 0.5rem;
		}
	}
}

.Link {
	display: flex;
	align-items: center;

	img {
		flex-shrink: 0;
		margin-right: 0.5rem;
	}
}

.Link--image {
	display: block;

	img {
		max-height: 75px;
	}
}
