// --------------------------------------------------------------------------------------------
// FLASH MESSAGE
// css pompé directement ici : https://github.com/twbs/bootstrap/blob/v4-dev/dist/css/bootstrap.css
// --------------------------------------------------------------------------------------------

.alert {
	padding: 0.75rem 1.25rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	position: relative;
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: bold;
}

.alert-dismissible .close {
	color: inherit;
	border: 0;
	padding: 0;
	cursor: pointer;
	background: transparent;
	@include hover {
		text-decoration: underline;
	}
}

.alert-success {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d0e9c6;
	hr {
		border-top-color: #c1e2b3;
	}
	.alert-link {
		color: #2b542c;
	}
}

.alert-info,
.alert-notice {
	color: #31708f;
	background-color: #d9edf7;
	border-color: #bcdff1;
	hr {
		border-top-color: #a6d5ec;
	} 
	.alert-link {
		color: #245269;
	}
}


.alert-warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
	border-color: #faf2cc;
	hr {
		border-top-color: #f7ecb5;
	}
	.alert-link {
		color: #66512c;
	}
}

.alert-danger {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebcccc;
	hr {
		border-top-color: #e4b9b9;
	}
	.alert-link {
		color: #843534;
	}
}