[role="tablist"] a {
	@include font-size(15.33);
	font-family: 'robotoregular', sans-serif;
	cursor: pointer;
    align-self: auto;
    display: block;
    flex: 1 1 auto;
	background: $verylightgrey;
	border-bottom: 1px solid $lightgrey;
	color: $grey;
	text-align: center;
	line-height: 2em;
	padding: .6em 30px;

	&[aria-selected="true"] {
		font-weight: bold;
		color: $white;
		background: $violet;
		border-color: $darkviolet;
	}
	@include hover {
		color: $white;
		background: $violet;
		border-color: $darkviolet;
	}

	&#label_infos_commande {
    	line-height: 1em;
	}
}

.js-tabcontent[aria-hidden=true] {
	display: none;
}