.Header {
	overflow: auto;
	display: table;
	padding: 1em 0 0 0;
	width: 100%;
}

.Header-logo {
	display: table-cell;
	@include media('>=tablet') {
    	width: 320px;
    }
}

.Header-baseline {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.Header--center {
	text-align: center;
}

@include media('<tablet') {
	.Header,
	.Header-logo,
	.Header-baseline {
		display: block;
	    text-align: center;
	    width: 100%;
	}

}

.Header-baseline {
	@include font-size(25);
	color: $violet;
	padding: 10px 50px;
	font-family: 'robotoblack', sans-serif;
	text-align: center;
	font-weight: normal;
	text-align: center;
	position: relative;
	margin-top: .25em;
	border-top: 1px solid $lightgrey;
	@include media('>=tablet') {
		border: 0;
	}
	&:after,
	&:before {
		content: "";
		width: 18px;
		height: 45px;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -22.5px;
		background: url('/images/window-left.png') no-repeat left center;
	}
	&:after {
		display: block;
		background: url('/images/window-right.png') no-repeat right center;
		right: auto;
		left: 0;
	}
}
