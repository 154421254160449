/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 19, 2017 */

@font-face {
    font-family: 'robotoblack';
    src: url('/fonts/roboto-black-webfont.woff2') format('woff2'),
         url('/fonts/roboto-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'robotolight';
    src: url('/fonts/roboto-light-webfont.woff2') format('woff2'),
         url('/fonts/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'robotoregular';
    src: url('/fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('/fonts/TitilliumWeb-Regular.woff2') format('woff2'),
         url('/fonts/TitilliumWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('/fonts/TitilliumWeb-Bold.woff2') format('woff2'),
         url('/fonts/TitilliumWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
