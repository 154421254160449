.Footer-menuList {
	display: block;
	text-align: center;
}

.Footer-menuItem {
	display: inline-block;
	&:last-child ::after {
		display: none;
	}
}

.Footer-menuLink {
	@include font-size(14);
	font-family: 'robotoregular', sans-serif;
	color: $white;
	text-decoration: none;
	&::after {
		display: inline-block;
		margin: 0 0 0 .25em;
		content: '|';
	}
	@include hover {
		text-decoration: underline;
	}
} 