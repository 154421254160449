@mixin clearfix() {
	&:before,
	&:after {
		content: "\0020";
		display: block;
		height: 0;
		overflow: hidden;
	}
	&:after {
		clear: both;
	}
	zoom: 1;
}

@mixin hover {
	&:hover,
	&:focus {
		@content;
	}
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}

@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}

@mixin font-size ( $size, $unit: rem ) {
	font-size: $size + px; // Fallback PX IE8
	font-size: ($size / $root-font-size) + $unit;
}

.titreArticle {
	background: $verylightgrey;
	color: $blue;
	font-family: 'robotoregular', sans-serif;
	@include font-size(25);
	margin-bottom: 1em;
	padding: .32em 0;
	text-align: center;
	word-wrap: break-word;

	@include media('<=phone') {
		@include font-size(17);
	}
}


.sousTitre {
	font-family: 'robotoblack', sans-serif;
	font-weight: normal;
	margin: .875em 0 .125em;
	padding: 0;
	text-align: left;
	@include font-size(16);
	&:first-of-type {
		margin-top: 0;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.sf-toolbar {
	display: none !important;
}
