/* base <tag> styles like a, h1, html, body
and only that: no classes, no ids */

*,
*:after,
*:before {
	box-sizing: border-box;
}

body,
html {
	margin: 0;
}

html {}
body {}


a {
	color: inherit;
	text-decoration: underline;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&:active {
		text-decoration: underline;
	}
}

a:not([href]),
a:active:not([href]) {
	text-decoration: none;
}

img {
	width: auto;
	max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p,
dl,
dt,
dd,
blockquote,
fieldset,
legend,
figure {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: normal;
}

input,
textarea,
select,
button {
	font: inherit;
	letter-spacing: inherit;
	color: $black;
}

option {
	color: initial;
}

ul {
	padding: 0;
	list-style: none;
}

fieldset {
	border: none;
}

cite {
	font-style: normal;
}

legend {
	color: inherit;
	display: block;
}