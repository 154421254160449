html, body {
  height: 100%;
}
html {
	background: $white;
	font-family: 'robotoregular', sans-serif;
}

.Layout {
	min-height: 100%;
	/* equal to footer height */
	margin-bottom: calc(-22px - 32px); ;
}

.Layout:after {
	content: "";
	display: block;
}

header,
.Main,
.Alerts ,
.Footer-menu {
	width: auto;
	display: block;
	margin: auto;
	padding: 10px 20px 0;
	@include media('>=desktop') {
		max-width: 890px;
	}
}

.Footer-menu {
	padding:0;
}

.Alerts {
	padding: 0 20px;
	margin: 0 auto !important;
	float: none !important;
}

.Main {
	padding-top: 1em;
}

.Main--noGutter {
	padding: 0;
	max-width: none;
}

footer, .Layout:after {
	min-height: calc(22px + 32px);
}


footer {
	background: $grey;
	padding: 1em 0;
}


.Brand {
	@include font-size(14);
	display: flex;
	flex-direction: row;
	align-items: center;
	box-shadow: 0px 0px 10px 0px #656565;
	background: white;
	width: 100%;
	height: 8rem;
	color: $grey;
	clear: both;
	float: left;
	margin-top: 1rem;

	> div {
		text-align: center;
		width: 33%;

		.Brand-logo {
			max-height: 6rem;
		}

		a {
			color: $blue;
		}
	}
}


.Brand-email {
	display: block;
	padding-top: 2.5rem;
	background: url('/images/email.png') no-repeat center top;
	color: $blue;
}
