$root-font-size: 16 !default;
$font-size: 16px !default;
$line-height: 1.5 !default;

$susy: (
  columns: 12,
  gutters: 1/2,
  math: fluid,
  output: float,
  gutter-position: inside,
);

$shorthand: 12 1/4 fluid float inside;


// default colors
$black: #000 !default;
$black-light: lighten($black, 75%) !default;
$white: #fff !default;
$white-light: lighten($black, 75%) !default;
// webdesign colors
$orange: #ff8000 !default;
$blue : #006f93 !default;
$violet: #813788 !default;
$darkviolet: #7b247b !default;
$grey: #646464 !default;
$mediumgray: #9C9C9C !default;
$lightgrey: #bbbbbb !default;
$verylightgrey: #eeeeee !default;
$lightergrey: #f5f5f5 !default;
$lightblue: #4994AC !default;





$breakpoints: (
  'phone': 26.25em,
  'tablet': 48em,
  'desktop': 64em
) !default;
