.Home {
	margin-top: 2rem;
	font-family: 'TitilliumWeb';
}

.Home-presentation {
	display: flex;

	@include media('>=desktop') {
		max-width: 890px;
		margin: 0 auto;
	}
}

.Home-presentationContent {
	width: 50%;
	padding: 1rem;
	flex-shrink: 0;
	text-align: right;
	color: $violet;

	p {
		margin-bottom: 1rem;
	}

	@media screen and (max-width: 42.8em) {
		width: 100%;
		padding: 1rem 2rem;
		text-align: left;
		margin-bottom: 1rem;
	}
}

.Home-presentationTitle {
	@include font-size(25);
	// line-height: 1.2;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: .5em;
}

.Home-presentationLinkWrapper {
	@media screen and (max-width: 42.8em) {
		text-align: center;
	}
}

.Home-presentationLink {
	@include font-size(14);
	display: inline-block;
	position: relative;
	height: 2rem;
    line-height: 1.75rem;
	padding-right: 2rem;
	padding-left: 0.5rem;
	z-index: 10;
    border-radius: 25px;
	border: 2px solid $violet;
    text-transform: uppercase;
	text-decoration: none !important;
	font-weight: bold;
	background-color: $white;

	&:after {
		content: '';
		position: absolute;
		right: 2px;
		top: 2px;
		width: 1.5rem;
		height: 1.5rem;
		background: url('/images/chevron_right.png') no-repeat center center;
		background-size: 1.5rem;
	}

	@include media('<=phone') {
		@include font-size(12);
	}
}

.Home-imagesWrapper {
	position: relative;
	margin-left: 1rem;

	@media screen and (max-width: 42.8em) {
		display: none;
	}
}

.Home-telephoneImg {
	position: relative;
	width: 20rem;
    z-index: 3;
}

.Home-etiquetteImg {
	position: absolute;
	left: 18rem;
    top: 5rem;
    width: 13rem;
    margin-left: -5rem;
    z-index: 1;
}

.Home-searchForm {
	position: relative;
	padding: 7rem 0 4rem;
	width: 100%;
	margin-top: -5.5rem;
	z-index: 2;
	text-align: center;
	background-color: $blue;
	clip-path: polygon(50% 15%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);

	@media screen and (max-width: 42.8em) {
		margin-top: 0;
	}

	@include media('<=phone') {
		padding: 3.5rem 0 2rem;
	}
}

.Home-helpLink {
	display: block;
	color: $lightblue;
}

.Home-searchFormInputWrapper {
	position: relative;
	height: 3rem;
	max-width: 25rem;
	margin: auto;
	border-radius: 25px;
	background-color: $white;

	@include media('<=phone') {
		max-width: 85%;
	}
}

.Home-searchInput {
	height: 3rem;
	width: 100%;
    padding-right: 3rem;
    padding-left: 1.5rem;
	border: none;
	background-color: transparent;

	&::placeholder {
		@include font-size(14);
		font-style: italic;
	}
}

.Home-submitButton {
	position: absolute;
	right: 4px;
	top: 4px;
	width: 2.5rem !important;
	height: 2.5rem !important;
	margin: 0 !important;
	padding: 0 !important;
	font-family: 'TitilliumWeb' !important;
	font-weight: bold !important;
	-webkit-appearance: inherit;
}

.Home-tooltipButton {
	@include font-size(18);
	height: 1.8rem;
	width: 1.8rem;
	padding: 0;
	font-weight: bold;
	border-radius: 50%;
	border: none;
	background-color: $lightblue;
	color: $white;
	-webkit-appearance: inherit;
}

.Home-reference {
	margin-right: 0.5rem;
	font-style: italic;
	color: $white;
}

.Home-moreInfosContainer {
	background-color: $lightergrey;
}

.Home-moreInfos {
	margin-top: -3rem;
	padding: 5rem 4rem 0;

	@include media('>=desktop') {
		max-width: 890px;
		margin-left: auto;
		margin-right: auto;
	}

	@include media('<=phone') {
		padding: 5rem 0 0;
	}
}

.Home-stepsTitle,
.Home-videoTitle,
.Home-stepsSubtitle {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	color: $blue;

	@media screen and (max-width: 42.8em) {
		text-align: left;
	}
}

.Home-stepsTitle {
	@include font-size(24);

	@include media('<=phone') {
		@include font-size(20);
	}
}

.Home-stepsSubtitle {
	@include font-size(15);
	margin-bottom: 2.5rem;

	@include media('<=phone') {
		@include font-size(13);
	}
}

.Home-videoTitle {
	@include font-size(24);
	width: 20%;
	text-align: right;
	margin-bottom: 0.5rem;

	@media screen and (max-width: 50em) {
		width: 100%;
		text-align: left;
	}

	@include media('<=phone') {
		@include font-size(20);
		text-align: center;
	}
}

.Home-steps {
	display: flex;
	margin-bottom: 6rem;
	background-color: $blue;

	@media screen and (max-width: 42.8em) {
		flex-direction: column;
		height: 65rem;
		width: 70%;
		margin: 0 auto 2rem;
	}
}

.Home-stepItem {
	@include font-size(13);
	width: 16%;
	padding: 2rem 0;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	color: $white;


	&:nth-child(even) {
		width: 28%;
		background-color: $lightblue;
		clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 20% 50%);

		@media screen and (max-width: 42.8em) {
			width: 100%;
			height: 24%;
			padding-top: 5rem;
			clip-path: polygon(50% 20%, 100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
		}
	}

	@media screen and (max-width: 42.8em) {
		width: 100%;
		height: 18%;
	}
}

.Home-stepIconContainer {
	display: flex;
    justify-content: center;
    align-items: center;
	height: 4.5rem;
	margin-bottom: 1rem;
}

.Home-stepIcon {
	width: 3.5rem;
}

.Home-videoSection {
	display: flex;
	justify-content: space-between;
	padding-bottom: 5rem;

	@media screen and (max-width: 50em) {
		flex-direction: column;
	}

	@include media('<=phone') {
		padding-bottom: 0;
	}
}

.Home-videoIframe {
	border: none;

	@media screen and (max-width: 50em) {
		width: 100%;
	}

	@include media('<=phone') {
		height: auto;
	}
}

.Home-titleWrapper {
	@include media('<=phone') {
		padding: 0 2rem;
	}
}
