form p {
	overflow: auto;
	zoom: 1;
	padding: 10px 0;
	margin: 0;
	&.submit {
		text-align: right;
	}
}

label {
	@include font-size(17);
	color: $grey;
	display: block;
	padding: 0 .5em;
	float: left;
	width: 25%;
	text-align: right;
	@include media('<tablet') {
		float: none;
		width: auto;
		text-align: left;
		padding: .5em 0 0 0;
	}
}

input,
textarea {
	border: 1px solid $grey;
	width: 75%;
	@include media('<tablet') {
		width: 95%;
	}
	&[type='file'] {
		border: 0;
	}
}

input[type='submit'] {
	background: $violet;
	border: 1px solid $violet;
	border-radius: 20px;
	color: $white;
	font-family: 'robotoblack', sans-serif;
	@include font-size(16.67);
	font-weight: normal;
	text-align: center;
	padding: .3em 1em;
	margin: 0 0 1em 0;
	cursor: pointer;
	width: auto;
	@include hover {
		background: $white;
		color: $violet;
	}
}

input[type="text"]:disabled {
	background: #eee;
}