.StaticPage-contentTitle {
	@extend .titreArticle;
}

.StaticPage--center {
	text-align: center;
}

.StaticPage {
	font-family: 'robotoregular', sans-serif;
	@include font-size(14);
	color: $grey;
	h4 {
		@extend .sousTitre;
	}
	p {
		margin: 0 0 .5em 0;
	}
	a {
		color: $blue;
	}
	strong,
	.emphasis {
		font-family: 'robotoblack', sans-serif;
		font-weight: normal;
	}
	.Document {
		text-align: center;
	}

	ul {
		list-style-type: disc;
		padding: 0 0 0 1.5em;
		li {
			padding:.25em 0;
		}
	}

	ul.Documents {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		list-style: none;
		margin: 1em 0;
		padding: .5em 0;
		
		li {
			width: (100% / 3);
			@include media('>phone') {
				width: (100% / 5);
			}
			@include media('>tablet') {
				width: (100% / 7);
			}
			padding: 0.75em;

			.a,
			a {
				display: block;

				img {
					max-width: 90%;
				}
			}
		}
	}

	.List-itemLabel {
		font-weight: bold;
	}
}

.StaticPage-list {
	margin-bottom: 2em;
}

.StaticPage-downloadLink {
	display: inline-block;
    vertical-align: middle;
    margin-left: 1em;
}

.StaticPage-separator {
	margin: 1em 0 !important;
}

.StaticPage--center .StaticPage-contentWrapper {
	width: 90%;
    text-align: center;
    display: inline-block;
}

.StaticPage--center .StaticPage-list {
	display: inline-block;
	text-align: left;
}

.StaticPage-contentSubtitle {
	@include font-size(32);
	margin-bottom: 1em;
	color: $darkviolet;
}